<template>
  <div class="row">
    <!-- <div class="col-2">
      <v-btn class="primary"  @click="sort">
        To original order
      </v-btn>
    </div> -->

    <!-- <div class="col-6">
      <h3>Transition</h3> -->
      <draggable
        v-model="list"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
      >
        <transition-group type="transition" > <!-- :name="!drag ? 'flip-list' : null" -->
          <div
            class="list-group-item"
            v-for="element in list"
            :key="element.order"
            
          >
            <span @click="element.fixed = !element.fixed">
                <!--
                    https://github.com/SortableJS/Vue.Draggable/blob/master/example/components/transition-example-2.vue
                    <i :class=" element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin' "
                        @click="element.fixed = !element.fixed"
                        aria-hidden="true">
                    </i> 
                -->

                <!-- <v-icon v-on="on"> {{ `mdi-${application.icon}` }} </v-icon> -->

                <!-- element.fixed = {{drag}} -->
                <!--  <v-icon v-if="element.fixed">mdi-pin-off-outline</v-icon>
                <v-icon v-else> mdi-pin-outline </v-icon>  -->
                <!-- {{dragOptions.ghostClass}} -->
           
                {{ element.name }}
            </span>
          </div>
        </transition-group>
      </draggable>
    <!-- </div> -->

  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  //name: "transition-example-2",

  components: {
    draggable
  },
  data() {
    return {
        // pin: "mdi-pin-outline",
        // iconPin: "mdi-pin-outline",
        // iconPinOff: "mdi-pin-off-outline",
        list: [
				{ name: "John", order: 1, fixed:true },
				{ name: "Joao", order: 2, fixed:true },
				{ name: "Jean", order: 3, fixed:true },
				{ name: "Gerard", order: 4, fixed:true },
				{ name: "Juan", order: 5, fixed:true },
				{ name: "Edgard", order: 6, fixed:true },
				{ name: "Johnson", order: 7, fixed:true },
        ],
      drag: false
    };
  },
  methods: {
    sort() {
      this.list = this.list.sort((a, b) => a.order - b.order);
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 500,        // tijd in ms
        //group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  }
};
</script>

<style>


/* .flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
} */

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

/* .list-group {
  min-height: 20px;
} */

.list-group-item {
  cursor: move;
}

.list-group-item span {
  cursor: pointer;
}
</style>